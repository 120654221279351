<template>
  <div>
    <div class="bg-light rounded-3 p-4 p-md-5 mb-3">

      <!-- Title -->
      <h2 class="h4 mb-5 text-center">
<!--        <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
        Please provide any notes for the client
      </h2>
      <!-- / Title -->

      <!-- Notes -->
      <div class="mb-4">
        <label class="form-label fw-bold" for="notes">Notes For Client</label>
        <textarea rows="4"
                  :disabled="isSaving" v-model="quote.notes"
                  class="form-control" id="notes"></textarea>
      </div>
      <!-- / Notes -->

      <!-- Post Job Acceptance Message -->
      <div class="mb-4">
        <label class="form-label fw-bold" for="postJobAcceptanceMessage">
          If your quote is accepted, what instructions do you want sent to the client?
        </label>
        <textarea rows="4"
                  :disabled="isSaving" v-model="quote.post_job_acceptance_message"
                  class="form-control" id="postJobAcceptanceMessage"></textarea>
      </div>
      <!-- / Post Job Acceptance Message -->

    </div>

    <!-- Footer Navigation -->
    <div class="wizard-footer-navigation">
      <!-- Previous -->
      <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
         @click.prevent="handlePreviousButton">
        <i class="fi-chevron-left fs-sm me-2"></i>
        Previous
      </a>
      <!-- / Previous -->

      <!-- Next -->
      <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
         @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
        Next
        <i class="fi-chevron-right fs-sm ms-2"></i>
      </a>
      <!-- / Next -->
    </div>
    <!-- / Footer Navigation -->
  </div>
</template>

<script>
export default {
  name: "QuoteDetails",
  props: ['quote'],
  computed: {
    canGoForward() {
      return true;
    }
  },
  methods: {
    handlePreviousButton() {
      this.$emit('go-back');
    },

    handleNextButton() {
      this.$emit('go-forward');
    }
  }
}
</script>

<style scoped>

</style>