<template>
  <div>
    <div class="bg-light rounded-3 p-4 p-md-4 mb-3">
      <div class="wizard-footer-navigation mt-0 d-flex justify-content-between">
        <!-- Previous -->
        <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
           :class="{'opacity-50': isSaving}"
           @click.prevent="handlePreviousButton">
          <i class="fi-chevron-left fs-sm me-2"></i>
          Previous
        </a>
        <!-- / Previous -->

        <!-- Next -->
        <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
           :class="{'opacity-50': isSaving}"
           @click.prevent="handleNextButton">
          <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>

          {{ isSaving ? 'Submitting Quote' : 'Submit Quote' }}
          <i class="fi-chevron-right fs-sm ms-2"></i>
        </a>
        <!-- / Next -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-3">
        <!-- Price details -->
        <div class="bg-light h-100 rounded-3 p-4 p-md-5">

          <!-- Title -->
          <h2 class="h4 mb-5 text-center">
            <!--        <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
            Quote Details
          </h2>
          <!-- / Title -->

          <div class="text-center mb-4">
            <b>Quote Type</b><br>
            <span v-if="quote.quoteType === 'hourly'">Hourly Rate</span>
            <span v-if="quote.quoteType === 'fixed-price'">Fixed Price</span>
            <span v-if="quote.quoteType === 'monthly-fee'">Monthly Fee</span>
          </div>

          <!-- Hourly rate details -->
          <div v-if="quote.quoteType === 'hourly'">

            <div class="text-center mb-4">
              <b>Hourly Rate</b><br>
              {{ formatCost(quote.hourlyRate) }}
            </div>

            <div class="text-center mb-4">
              <b>Estimated Hours</b><br>
              {{ quote.estimatedHours }}
            </div>

          </div>
          <!-- / Hourly rate details -->

          <!-- Fixed fee details -->
          <div v-if="quote.quoteType === 'fixed-price'">
            <div class="text-center mb-4">
              <b>Fixed Price</b><br>
              {{ formatCost(quote.fixedPrice) }}
            </div>
          </div>
          <!-- Fixed fee details -->

          <!-- Monthly fee details -->
          <div v-if="quote.quoteType === 'monthly-fee'">
            <div class="text-center mb-4">
              <b>Monthly Fee</b><br>
              {{ formatCost(quote.monthlyFee) }}
            </div>
            <div class="text-center mb-4">
              <b>Start Date</b><br>
              {{ quote.startDate }}
            </div>
            <div class="text-center mb-4">
              <b>Minimum Term</b><br>
              {{ quote.minimumTermMonths }} {{ quote.minimumTermMonths == 1 ? 'month' : 'months' }}
            </div>
          </div>
          <!-- Monthly fee details -->

          <div class="text-center mb-4">
            <b>Deposit Required</b><br>
            {{ formatCost(quote.depositRequired) }}
          </div>

          <div class="text-center">
            <b>VAT Rate</b><br>
            {{ quote.vatRate }}%
          </div>

        </div>
        <!-- / Price details -->
      </div>

      <div class="col-md-6 mb-3">
        <!-- Notes for client -->
        <div class="bg-light h-100 rounded-3 p-4 p-md-5">

          <!-- Title -->
          <h2 class="h4 mb-5 text-center">
            <!--        <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
            Notes For Client
          </h2>
          <!-- / Title -->

          <div class="text-center mb-4">
            <b>Notes For Client</b><br>
            {{ quote.notes }}
          </div>

          <div class="text-center">
            <b>If your quote is accepted, what instructions do you want sent to the client?</b><br>
            {{ quote.post_job_acceptance_message }}
          </div>

        </div>
        <!-- / Notes for client -->
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../../../../components/Spinner";
import Display from "../../../../mixins/Display";

export default {
  name: "Review",
  props: ['isSaving', 'quote'],
  mixins: [Display],
  components: {Spinner},
  methods: {
    handlePreviousButton() {
      if (this.isSaving) {
        return false;
      }
      this.$emit('go-back');
    },

    handleNextButton() {
      if (this.isSaving) {
        return false;
      }
      this.$emit('submit-quote');
    }
  }
}
</script>

<style scoped>

</style>