import axios from 'axios';

export default {
    fetch() {
        return axios.get(window.API_BASE + '/company');
    },

    getDefaultPostJobAcceptanceMessage() {
        return axios.get(window.API_BASE + '/company/default-post-job-acceptance-message');
    }
}