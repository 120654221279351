<template>
  <div class="card card-hover h-100" @click.prevent="select">
    <div class="card-body text-center">
      <div class="text-center pt-3 mb-4">
        <i :class="iconClass"></i>
      </div>
      <h5 class="card-title">{{ quoteType.label }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: ["quoteType"],
  methods: {
    select() {
      this.$emit('selected');
    }
  },
  computed: {
    iconClass() {
      return this.quoteType.icon + ' icon';
    }
  }
};
</script>

<style scoped>
.card-hover:hover {
  background-color: rgba(var(--bs-secondary-rgb), 0.6);
  cursor: pointer;
}

.icon {
  font-size: 60px;
  color: #3B566E;
}
</style>